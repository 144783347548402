/* custom classes */
.containers {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.w-100 {
    width: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
}

.navbar-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.nav-width {
    width: 35%;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    min-height: 60px;
}

.nav-width h1 {
    /* border: 1px solid green; */
    font-size: 1.2rem;
    padding: 0;
    margin: 0;
}


.nav-width-1 {
    width: 70%;
    /* border: 1px solid orange; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-width-2 {
    width: 10%;
}

.navbar-btns {
    width: 25%;
    /* border: 1px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}


.navbar {
    height: 78px;
    background-color: var(--color-bg);
}

.navbar .btn {
    width: max-content;
    display: inline-block;
    color: var(--color-bg);
    padding: 0.8rem 1.7rem;
    border-radius: .5rem;
    cursor: pointer;
    border: transparent;
    transition: var(--transition);
    font-size: .8rem;
    font-weight: 600;
}

.hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 26px;
    width: 34px;
    cursor: pointer;
    z-index: 999;
}

.bar-top,
.bar-middle,
.bar-bottom {
    height: 3px;
    background-color: #fff;
    transform-origin: left;
    transition: var(--transition);
}

.hamburger-menu-change .bar-top {
    transform: rotate(42deg);
}

.hamburger-menu-change .bar-middle {
    transform: translateX(1rem);
    opacity: 0;
}

.hamburger-menu-change .bar-bottom {
    transform: rotate(-42deg);
}

.navbar-collapse {
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--color-bg);
    height: 100%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translateX(100%);
    transition: var(--transition);
    z-index: 998;
}

.show-navbar-collapse {
    transform: translateX(0);
}

.navbar-collapse-content {
    padding: 40px;
    /* border: 1px solid green; */
    min-height: 60px;
}


.navbar-nav li {
    color: var(--color-bg);
    margin: 30px 0;
    font-size: 1.3rem;
    transition: var(--transition);
    display: flex;
}



@media screen and (min-width: 992px) {
    .navbar-collapse {
        transform: translateX(0);
        position: relative;
        background-color: transparent;
        box-shadow: none;
        top: 0;
        height: auto;
        width: 100%;
    }

    .navbar-collapse-content {
        padding: 0;
        display: flex;
        align-items: center;
    }

    .hamburger-menu {
        display: none;
    }

    .navbar-nav {
        display: flex !important;
        margin-bottom: 0;
    }

    .navbar-btns {
        margin-top: 0;
        margin-left: 8px;
    }



    .navbar-nav li {
        margin: 0;
        font-size: 1.1rem;
        font-weight: 500;
        margin-right: 36px;

    }

    .navbar-nav li:hover {
        color: #3671E9;
    }


}

@media screen and (max-width: 992px) {
    .nav-width {
        width: 100%;
    }

    .nav-width-1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0;
    }
}


@media(max-width:600px) {
    .navbar-collapse {
        width: 320px;
    }

    .navbar .btn {
        color: var(--color-bg);
        font-weight: 900;
        background-color: black;
    }

    .navbar .btn:hover {
        color: var(--color-bg);
        background-color: var(--color-white);
    }


    .navbar-nav li {
        font-weight: 600;
        padding: 0;
    }


}