@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-size: 1rem;

}

:root {
  --color-bg: #000A1F;
  --color-bg-variant: #2c2c6c;
  --color-primary: #00C7FF;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --transition: all 400ms ease;
  --container-width-lg: 80%;
  --container-width-md: 90%;
  --container-width-sm: 98%;

  --padding-bottom-lg: 3rem;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Outfit', sans-serif; */
  color: var(--color-white);
  line-height: 1.2;
  background-color: #F0F3F7;
}

.span {
  font-size: 5rem;
  max-width: 1100px;
  text-align: center;
  color: var(--color-primary);
}

.margin-bottom-lg {
  margin-bottom: 4em;
}

.margin-bottom-md {
  margin-bottom: 2em;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.flex-between {
  justify-content: space-between;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* GENERAL STYLES */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
  justify-content: center;
  align-items: center;

}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: var(--color-white);
}

h1 {
  font-size: 1.3rem;
  max-width: 1100px;
  text-align: center;
}

ul {
  display: flex;
}

#ul {
  display: flex;
  flex-direction: row;
}

a {
  color: #fff;
  display: flex;
  text-decoration: none;
}

a:hover {
  color: var(--color-primary);
  transition: var(--transition);
}


section {
  margin-top: 3rem;
  padding: 3rem 0;
}

section>h2 {
  margin-bottom: 1rem;
}

.text-light {
  color: var(--color-light);
}


#btn {
  font-size: .9rem;
  background-color: var(--color-primary);
}

#btn:hover {
  font-size: .9rem;
  background-color: var(--color-white);
}

.btns {
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 0.6rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.0015em;
  text-transform: capitalize;
  transition: var(--transition);
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg-variant);
  transition: var(--transition);
  border: 1px solid var(--color-primary);
}

.btn-primary:hover {
  transform: scale(1.05);
}



.selected-input {
  /* background-color: #e6e6e6; */
  border: 2px solid var(--color-bg-variant);
  color: red;
}


/* PAGINATION-SEARCH */
#pagination-column {
  padding-top: 1em;
}

.pagination-search {
  margin-bottom: 2em;
  display: flex;
  background-color: var(--color-bg);
  padding: 0 .6em;
}

.pagination-search .inner-1 {
  width: 70%;
  display: flex;
  align-items: center;
  margin: auto;
  min-height: 70px;
}

.pagination-search .inner-2 {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.main-table {
  width: 100%;
  color: var(--color-bg);
  border: none;
}

.main-table-1 {
  width: 100%;
  overflow: none;
}





/* TABLE PAGINATION */

.title-col {
  border: 1px solid yellow;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-col h3 {
  border: 1px solid blue;
  font-size: 1.3rem;
  text-transform: capitalize;
}

.search-col {
  padding: .5em;
  width: 100%;
  display: flex;
  justify-content: flex-end;

}

.search-col input {
  padding: .5em;
  width: 100%;
  border-radius: 5px;
}

.search-col-2 {
  border: 1px solid yellow;
  width: 100%;
}

.table-body-col {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
}


/* TABLE HEADER */

#table-header {
  min-width: 100%;
  display: flex;
  min-height: 50px;
  border: 1px solid var(--color-bg);

}

#table-header h4 {
  font-size: .9rem;
  color: var(--color-bg);
  padding: 0;
  margin: 0;

}

#table-header .col-1 {
  /* border: 1px solid white; */
  min-width: 9.9%;
  padding: 1.2em .4em;
}


#table-header .col-2,
.col-3,
.col-4,
.col-5 {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-bg);
  border-top: none;
  border-bottom: 0;
  border-right: 0;

}

#table-header .col-2 {
  width: 22.5%;
  padding-left: .4em;

  font-size: .8em;
}

#table-header .col-3 {
  width: 22.5%;
  padding-left: .4em;
}

#table-header .col-4 {
  width: 22.5%;
  padding-left: .4em;
}

#table-header .col-5 {
  width: 22.5%;
  padding-left: .4em;
}


.arrow {
  margin: .5em;
}




/* TABLE BODY */

.table-inner-main {
  max-width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  border: 1px solid var(--color-bg);
}

.table-inner-main h4 {
  font-size: .8rem;
  color: var(--color-bg);
  font-weight: 500;
  padding: 0;
  margin: 0;
  /* border: 1px solid red; */
}

#table-inner-1 {
  border-right: 1px solid var(--color-bg);
  min-width: 10%;
  min-height: 50px;
  padding: .4em;
  margin: 0;
  display: flex;
  align-items: center;
}

#table-inner-2 {
  border-right: 1px solid var(--color-bg);
  width: 22.5%;
  min-height: 50px;
  padding: .4em;
  margin: 0;
  display: flex;
  align-items: center;
}

#table-inner-3 {
  border-right: 1px solid var(--color-bg);
  width: 22.5%;
  min-height: 50px;
  padding: .4em;
  display: flex;
  align-items: center;
}

#table-inner-4 {
  border-right: 1px solid var(--color-bg);
  width: 22.5%;
  min-height: 50px;
  padding: .4em;
  display: flex;
  align-items: center;
}


#table-inner-5 {
  width: 22.5%;
  min-height: 50px;
  padding: .4em;
  display: flex;
  align-items: center;
}

#table-inner-5 input {
  max-width: 80%;
  background-color: transparent;
  color: var(--color-bg);
}

#table-inner-5 input::placeholder {
  color: #D3D3D3;
}


/* SECOND TABLE */

.secondTable {
  display: flex;
  margin-top: 2rem;
  border: 1px solid var(--color-bg);
}

.secondTableHeader {
  display: flex;
  width: 100%;
  border: 1px solid var(--color-bg);
}

.secondTableHeader #col-1 {
  min-width: 22.5%;
  padding: .1em .4em;
  border-right: 1px solid var(--color-bg);
}

.secondTableHeader #col-2 {
  min-width: 22.5%;
  padding: .1em .4em;
  border-right: 1px solid var(--color-bg);
}

.secondTableHeader #col-3 {
  min-width: 22.5%;
  padding: .1em .4em;
  border-right: 1px solid var(--color-bg);
}

.secondTableHeader #col-4 {
  min-width: 22.5%;
  padding: .1em .4em;
}

.secondTableHeader h4 {
  font-size: .8rem;
  color: var(--color-bg);
  padding: 1.2em .4em;
}



.secondTableBody {
  display: flex;
  width: 100%;
  border: 1px solid var(--color-bg);
}

.secondTableBody #col-1 {
  min-width: 22.5%;
  padding: .1em .4em;
  border-right: 1px solid var(--color-bg);
}

.secondTableBody #col-2 {
  min-width: 22.5%;
  padding: .1em .4em;
  border-right: 1px solid var(--color-bg);
}

.secondTableBody #col-3 {
  min-width: 22.5%;
  padding: .1em .4em;
  border-right: 1px solid var(--color-bg);
}

.secondTableBody #col-4 {
  min-width: 22.5%;
  padding: .1em .4em;
}

.secondTableBody h4 {
  font-size: .8rem;
  color: var(--color-bg);
  padding: 1.2em .4em;
  font-weight: 400;
  /* max-width: 180px; */
}


.secondTable h4 {
  font-size: .8rem;
  font-weight: 500;
  padding: 0;
  margin: 0;
  color: var(--color-bg);
  /* border: 1px solid red; */
}


.selectedTable {
  min-height: 100px;
  /* border: 1px solid red; */
  margin-top: 1em;
}





#secondTableMain {
  overflow: scroll;
}


.MainTableThree {
  background-color: var(--color-bg);
  margin-bottom: 3em;
}



.tableMains {
  width: 100%;
}

.tableMains .th0 {
  width: 7%;
}

.tableMains .th1 {
  width: 35%;
}

.tableMains .th2 {
  width: 26.5%;
}

.tableMains .td1 {
  max-width: 16.5%;
}










/* MEDIA QUERIES TABLETS */
@media screen and (max-width: 1024px) {


  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }

}


/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 1010px) {

  h1 {
    font-size: 3rem;
  }

  .span {
    font-size: 4rem;
  }

  .container {
    width: var(--container-width-sm);
  }


  /* PAGINATION-SEARCH MEDIA QUERIES*/
  .pagination-search {
    border: none;
    display: flex;
    flex-direction: column;
  }

  .pagination-search .inner-1 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .pagination-search .inner-2 {
    width: 100%;
  }

  #search-col {
    padding: .5em 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #table-header h4 {
    font-size: .8rem;
  }



  #table-header {
    max-width: 100%;
    display: flex;
    min-height: 50px;
    border: 1px solid var(--color-bg);


  }

  #table-header .col-1 {
    /* border: 1px solid white; */
    min-width: 9.9%;
    padding: 1.2em .4em;

  }


  #table-header .col-2 {
    width: 22.5%;
    padding-left: .4em;
    /* background-color: blue; */
  }

  #table-header .col-3 {
    width: 18.5%;
    padding-left: .4em;
  }

  #table-header .col-4 {
    width: 18.5%;
    padding-left: .4em;
  }

  #table-header .col-5 {
    width: 17.5%;
    padding-left: .4em;
  }




  /* TABLE BODY */

  .table-inner-main {
    max-width: 100%;
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    border: 1px solid var(--color-bg);
  }



  #table-inner-1 {
    border-right: 1px solid var(--color-bg);
    min-width: 10%;
    min-height: 50px;
    padding: .4em;
    margin: 0;
    display: flex;
    align-items: center;

  }

  #table-inner-2 {
    border-right: 1px solid var(--color-bg);
    width: 22.5%;
    min-height: 50px;
    padding: .4em;
    margin: 0;
    display: flex;
    align-items: center;
    /* background-color: red; */
  }

  #table-inner-3 {
    border-right: 1px solid var(--color-bg);
    width: 22.5%;
    min-height: 50px;
    padding: .4em;
    display: flex;
    align-items: center;
  }

  #table-inner-4 {
    border-right: 1px solid var(--color-bg);
    width: 22.5%;
    min-height: 50px;
    padding: .4em;
    display: flex;
    align-items: center;

  }


  #table-inner-5 {
    width: 22.5%;
    min-height: 50px;
    padding: .4em;
    display: flex;
    align-items: center;
  }

  #table-inner-5 label {
    width: 90%;
  }


  #table-inner-5 input {
    max-width: 100%;
    background-color: transparent;
    color: var(--color-bg);

  }



  #table-inner-5 input::placeholder {
    color: #D3D3D3;
    font-size: .9rem;
  }



  #ul {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    margin-left: 0;
  }


  .main-table-1 {
    max-width: 100%;
    overflow: scroll;
  }


  .table-body-col {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 150%;
    overflow: scroll;
  }


  .secondTableHeader {
    display: flex;
    max-width: 100%;
  }

  /* .secondTableHeader #col-1 {
    min-width: 37.5%;
  }

  .secondTableHeader #col-2 {
    min-width: 37.5%;
  }

  .secondTableHeader #col-3 {
    min-width: 37.5%;
  }

  .secondTableHeader #col-4 {
    min-width: 37.5%;
  } */

  /* SELECTED STOCK BODY */

  #table-body-col {
    width: 100%;
  }

  .secondTableBody {
    display: flex;
    max-width: 100%;
    border: 1px solid var(--color-bg);
  }

  .secondTableBody h4 {
    max-width: 90px;
  }
}